<template>
  <b-card :title="$t('message.insertPost')">
    <v-select
      v-model="selected1"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="books"
      :label="'title'"
    >
      <template #option="{ titl1, iva }">
        <span><strong>{{ iva }} </strong></span>
        <br>
        <span> {{ titl1 }}</span>
      </template>
    </v-select>
    <b-row>
      <b-col>
        <b-form
          @submit.prevent="addPost"
        >
          <b-form-group
            label="Inserisci Titolo"
            label-for="post-title"
          >
            <b-form-input
              id="post-title"
              v-model="title"
              name="title"
            />
          </b-form-group>
          <b-form-group
            label="Inserisci descrizione"
            label-for="post-description"
          >
            <b-form-input
              id="post-description"
              v-model="description"
              name="description"
            />
          </b-form-group>
          <b-button type="submit">
            Aggiungi Post
          </b-button>
          <b-button
            @click="readAllPost"
          >
            Leggi Post
          </b-button>
        </b-form>
      </b-col>
      <b-col>
        <b-table
          small
          :fields="fields"
          :items="items"
          responsive="sm"
        >

          <!-- A virtual composite column -->
          <template>
            <b-button
              variant="outline-primary"
              class="btn-icon"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell(action)="data">
            <b-button
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="deletePost(data)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="$bvModal.show(`modal-sm${data.item.id}`)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-modal
              :id="`modal-sm${data.item.id}`"
              cancel-variant="secondary"
              ok-only
              ok-title="Conferma"
              centered
              size="sm"
              :title="`Modifica Post ${data.item.id}`"
              @ok.prevent="editPost(data)"
            >
              <b-form-group
                label="Inserisci Titolo"
                label-for="post-title"
              >
                <b-form-input
                  id="post-title"
                  v-model="data.item.title"
                  name="title"
                />
              </b-form-group>
              <b-form-group
                label="Inserisci Descrizione"
                label-for="post-description"
              >
                <b-form-input
                  id="post-description"
                  v-model="data.item.description"
                  name="title"
                />
              </b-form-group>
            </b-modal>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h1>Google APIs example - oAuth2 (vuex)</h1>
        <p>isReady: {{ gauthReady }}</p>
        <p>isSignedIn: {{ isSignedIn }}</p>
        <p v-if="isSignedIn">
          {{ user }}
        </p>
        <button
          :disabled="isSignedIn || !gauthReady"
          @click="signIn"
        >Sign In</button>
        <button
          :disabled="!isSignedIn || !gauthReady"
          @click="signOut"
        >Sign Out</button>
        <button
          :disabled="!isSignedIn || !gauthReady"
          @click="disconnect"
        >Disconnect</button>
        {{ user }}
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BButton,
  BTable,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BTable,
    BModal,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected1: {
        title: 'Aperture',
        icon: 'ApertureIcon',
      },
      books: [
        {
          title: 'Aperture',
          iva: 'TRRDSADAAASDA',
        },
        {
          title: 'Codepen',
          iva: 'TRRDSDASDADDADAAASDA',
        },
        {
          title: 'Globe ',
          iva: 'TRRDSADAAAAAAASDA',
        },
        {
          title: 'Instagram ',
          iva: 'T1RRDSA211DAAASDA',
        },
      ],
      title: '',
      description: '',
      fields: [
        // A virtual column that doesn't exist in items
        { key: 'id', label: 'ID' },
        // A column that needs custom formatting
        { key: 'title', label: 'Titolo' },

        // A virtual column made up from two fields
        { key: 'description', label: 'Descrizione' },
        { key: 'action', label: 'Azioni' },
      ],
      items: [
        {
          id: 1,
          title: 'Titolo1',
          description: 'Desrizione1',
        },
        {
          id: 2,
          title: 'Titolo2',
          description: 'Desrizione2',
        },
        {
          id: 3,
          title: 'Titolo3',
          description: 'Desrizione3',
        },
        {
          id: 4,
          title: 'Titolo4',
          description: 'Desrizione4',
        },
      ],
      token: `Bearer ${sessionStorage.getItem('token')}`,
      signedIn: false,
    }
  },
  computed: {
    ...mapGetters('authGoogle', {
      gauthReady: 'isReady',
      isSignedIn: 'isSignedIn',
      user: 'getUser',
    }),
  },
  created() {
    // console.log(this.token)
    // this.getEvents()
    this.apiGoogle()
    // this.isSignedId()
  },
  methods: {
    ...mapActions('authGoogle', {
      signIn: 'signIn',
      signOut: 'signOut',
      disconnect: 'disconnect',
    }),
    apiGoogle() {
      this.$store.dispatch('authGoogle/init')
    },
    async getEvents() {
      await axios.get('www.googleapis.com/calendar/v3/calendars/alberto.torrisi5%40gmail.com/events?timeMin=2021-08-25T10%3A00%3A00Z&key=AIzaSyBru1tgLgw5_84eowWVLxham3O-k15IPxk',
        {}, {
          headers: {
            Authorization: 'Bearer ya29.a0ARrdaM8S3H4P9VH7Zt_96OGovXljX_aj5qaQF6sLSlg6kbnw0lVRpYjAONecA2Ia1ZR7zM4_V4LmGlkKM6nyKcs61QHzOe5Z4EPBmHSvCq3EDYnX4VHk_wcr75YvzImTJ7dwnt8Ntd-D3Ix5-uXK_CxGXqm_rA',
          },
        })
        .then(res => res)
        .catch(e => e)
    },
    // SOLO POST PER I POSTS IMPLEMENTATA
    async getPost() {
      await axios.get('https://manager.petergoodstrong.com/api/posts', {},
        {
          headers: {
            Authorization: this.token,
          },
        })
        .then(() => {
          // console.log(res)
        })
        .catch(() => {
          // console.log(e)
        })
    },
    async addPost() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, {
        title: this.title,
        description: this.description,
        // token: this.token,
      },
      {
        headers: {
          Authorization: this.token,
          organizzazione: 129,
          classe: 'Post',
          metodo: 'registra',
        },
      })
        .then(() => {
          // console.log('res posts', res)
          /* this.$swal({
            title: 'Ottime notizie',
            text: 'Post Inserito con successo ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }) */
        })
        .catch(() => {
          this.$swal({
            title: 'Errore',
            text: 'Post NON Inserito con successo ',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    async readAllPost() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, {
        // token: this.token,
      },
      {
        headers: {
          Authorization: this.token,
          organizzazione: 129,
          classe: 'Post',
          metodo: 'list',
        },
      })
        .then(() => {
          // console.log('res all post', res)
          /* this.$swal({
            title: 'Ottime notizie',
            text: 'Post Inserito con successo ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }) */
        })
        .catch(e => e)
    },
    async addPost1() {
      await axios.post('https://manager.petergoodstrong.com/api/posts', {
        title: this.title,
        description: this.description,
        // token: this.token,
      },
      {
        headers: {
          Authorization: this.token,
          organizzazione: 129,
        },
      })
        .then(() => {
          this.$swal({
            title: 'Ottime notizie',
            text: 'Post Inserito con successo ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(() => {
          this.$swal({
            title: 'Errore',
            text: 'Post NON Inserito con successo ',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    async deletePost(data) {
      // console.log('data', data.item.id)
      await axios.post('https://manager.petergoodstrong.com/api/login', {
        token: this.token,
        data: data.item.id,
      })
        .then(() => {
          this.$swal({
            title: 'Ottime notizie',
            text: 'Post Cancellato con successo ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(() => {
          this.$swal({
            title: 'Errore',
            text: 'Post NON Cancellato con successo ',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    async editPost(data) {
      await axios.post('https://manager.petergoodstrong.com/api/login', {
        token: this.token,
        data: data.item.id,
      })
        .then(() => {
          this.$swal({
            title: 'Ottime notizie',
            text: 'Post Cancellato con successo ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(() => {
          this.$swal({
            title: 'Errore',
            text: 'Post NON Cancellato con successo ',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.radius div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.radius input:enabled {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.v-select div#vs1__combobox{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.radiusleft {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
